import Image from "next/image";
import GoogleLoginButton from "../GoogleLoginButton";
import PrivacyLink from "../PrivacyLink";

interface Props {
  isLoading?: boolean;
  writeKey?: string;
}

const LoginSection = ({ isLoading = false, writeKey }: Props): JSX.Element => {
  return (
    <section className="flex w-[488px] flex-col items-center justify-center gap-8 p-16 bg-white rounded-[32px] border border-charcoal_200">
      <Image
        alt="landwich logo"
        height={24}
        src="/logo/landwich-logo.png"
        width={120}
      />
      <GoogleLoginButton isLoading={isLoading} writeKey={writeKey} />
      <div className="flex flex-col gap-2">
        <PrivacyLink
          isLoading={isLoading}
          url="https://landwi.ch/privacy-policy"
          value="Privacy of Policy"
        />
        <PrivacyLink
          isLoading={isLoading}
          url="https://landwi.ch/terms-of-use"
          value="Terms of Use"
        />
      </div>
    </section>
  );
};

export default LoginSection;
