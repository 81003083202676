"use client";

import { Button, LoadingSpinner } from "@landwich/ds";
import cn from "classnames";
import { getGoogleOAuthServerUrl } from "../../utils/getGoogleOAuthServerUrl";

interface Props {
  isLoading?: boolean;
  writeKey?: string;
  isSignUp?: boolean;
}

const GoogleLoginButton = ({
  isLoading = false,
  writeKey,
  isSignUp = false,
}: Props): JSX.Element => {
  const handleGoogleLoginButtonClick = (): void => {
    location.href = getGoogleOAuthServerUrl(writeKey);
  };

  if (isLoading) {
    return (
      <div className="w-[360px] px-8 py-3 bg-charcoal_200 flex rounded-lg justify-center">
        <LoadingSpinner color="charcoal_400" size="sm" />
      </div>
    );
  }

  return (
    <Button
      className={cn("min-w-[360px]", {
        "bg-charcoal_300 opacity-50": isLoading,
      })}
      color="white"
      onClick={handleGoogleLoginButtonClick}
      rounded="rounded-lg"
    >
      <div className="flex flex-[10px] justify-center gap-[8px] rounded">
        <div>
          <svg
            fill="none"
            height="19"
            viewBox="0 0 19 19"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M18.14 9.70456C18.14 9.06637 18.0827 8.45274 17.9764 7.86365H9.5V11.345H14.3436C14.135 12.47 13.5009 13.4232 12.5477 14.0614V16.3196H15.4564C17.1582 14.7527 18.14 12.4455 18.14 9.70456Z"
              fill="#4285F4"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M9.5 18.5C11.93 18.5 13.9673 17.6941 15.4564 16.3195L12.5477 14.0614C11.7418 14.6014 10.7109 14.9204 9.5 14.9204C7.15591 14.9204 5.17182 13.3373 4.46409 11.21H1.45728V13.5418C2.93818 16.4832 5.98182 18.5 9.5 18.5Z"
              fill="#34A853"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M4.46409 11.21C4.28409 10.67 4.18182 10.0932 4.18182 9.50001C4.18182 8.90683 4.28409 8.33001 4.46409 7.79001V5.45819H1.45727C0.847727 6.67319 0.5 8.04774 0.5 9.50001C0.5 10.9523 0.847727 12.3268 1.45727 13.5418L4.46409 11.21Z"
              fill="#FBBC05"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M9.5 4.07955C10.8214 4.07955 12.0077 4.53364 12.9405 5.42545L15.5218 2.84409C13.9632 1.39182 11.9259 0.5 9.5 0.5C5.98182 0.5 2.93818 2.51682 1.45728 5.45818L4.46409 7.79C5.17182 5.66273 7.15591 4.07955 9.5 4.07955Z"
              fill="#EA4335"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <div className="text-md-semibold text-charcoal_500">
          Sign {isSignUp ? "up" : "in"} with Google
        </div>
      </div>
    </Button>
  );
};

export default GoogleLoginButton;
