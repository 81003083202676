export const getGoogleLoginRedirectUrl = (writeKey?: string): string => {
  const isLocalhost = window.location.hostname.includes("localhost");

  const baseUrl =
    writeKey && isLocalhost
      ? `http://${window.location.host}`
      : `https://${window.location.host}`;

  const redirectUrl = "login/redirect";

  const path = writeKey ? `figma/${redirectUrl}` : redirectUrl;

  return `${baseUrl}/${path}`;
};
