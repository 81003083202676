import { openLinkByNewWindow } from "@landwich/shared/utils";
import cn from "classnames";

interface Props {
  isLoading?: boolean;
  value: string;
  url: string;
}

const PrivacyLink = ({ isLoading = false, value, url }: Props): JSX.Element => {
  const onLinkClick = () => {
    openLinkByNewWindow(url);
  };

  return (
    <div
      className={cn(
        "text-center text-md-medium text-charcoal_400 hover:text-charcoal_400 hover:cursor-pointer",
        {
          "!text-charcoal_500 hover:text-charcoal_500 pointer-events-none":
            isLoading,
        }
      )}
      onClick={onLinkClick}
    >
      {value}
    </div>
  );
};

export default PrivacyLink;
