import { isUndefined } from "@landwich/shared/utils";
import { getGoogleLoginRedirectUrl } from "./getGoogleLoginRedirectUrl";

const getClientID = (): string => {
  if (!isUndefined(process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID)) {
    return process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID;
  }
  return process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID_DEV ?? "";
};

export const getGoogleOAuthServerUrl = (writeKey?: string): string => {
  const GOOGLE_LOGIN_URL = "https://accounts.google.com/o/oauth2/v2/auth";

  const clientId = getClientID();
  const redirectURL = getGoogleLoginRedirectUrl(writeKey);

  const googleAuthUrl = `${GOOGLE_LOGIN_URL}?client_id=${clientId}&redirect_uri=${redirectURL}&access_type=offline&include_granted_scopes=true&response_type=code&scope=https%3A//www.googleapis.com/auth/userinfo.profile+https%3A//www.googleapis.com/auth/userinfo.email`;

  if (writeKey) {
    return `${googleAuthUrl}&state=${writeKey}`;
  }

  return googleAuthUrl;
};
